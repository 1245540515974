import { NavLink } from "react-router-dom";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import React, { FC, useState } from 'react';

import styles from './Navigation.module.css';

export const Navigation: FC = () => {
    const [opened, setOpened] = useState(false);
    const toggleMenuOpened = () => setOpened(!opened);
    const closeMenu = () => setOpened(false);

    return (
        <nav className={styles.navigation}>
            <h1 className={styles.logo}>PixTapes</h1>

            <div>
                <button className={styles.more} onClick={toggleMenuOpened}>Menu</button>
                <div className={styles.menu + (opened ? " " + styles.opened : "")}>
                    <NavLink
                        to="/"
                        className={({ isActive }) => styles.entry + (isActive ? " " + styles.active : "")}
                        onClick={closeMenu}>
                        Home
                    </NavLink>
                    <NavLink
                        to="/browse"
                        className={({ isActive }) => styles.entry + (isActive ? " " + styles.active : "")}
                        onClick={closeMenu}>
                        Browse
                    </NavLink>
                    <NavLink
                        to="/vote"
                        className={({ isActive }) => styles.entry + (isActive ? " " + styles.active : "")}
                        onClick={closeMenu}>
                        Vote
                    </NavLink>
                    <NavLink
                        to="/wallet"
                        className={({ isActive }) => styles.entry + (isActive ? " " + styles.active : "")}
                        onClick={closeMenu}>
                        Wallet
                    </NavLink>
                    <NavLink
                        to="/my-tapes"
                        className={({ isActive }) => styles.entry + (isActive ? " " + styles.active : "")}
                        onClick={closeMenu}>
                        My Tapes
                    </NavLink>
                </div>

                <div className="wallet-buttons">
                    <WalletMultiButton />
                </div>
            </div>
        </nav>
    );
};
