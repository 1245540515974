import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { AppBody } from './AppBody';

import styles from "./App.module.css";

const Home = lazy(() => import('./routes/Home'));
const MyTapes = lazy(() => import('./routes/MyTapes'));
const Tape = lazy(() => import('./routes/Tape'));
const Browse = lazy(() => import('./routes/Browse'));
const VoteList = lazy(() => import('./routes/VoteList'));
const Wallet = lazy(() => import('./routes/Wallet'));
const User = lazy(() => import('./routes/User'));

const Wiki = lazy(() => import('./routes/Wiki'));
const TheTeam = lazy(() => import('./routes/TheTeam'));
const RarityChart = lazy(() => import('./routes/RarityChart'));
const Rewind = lazy(() => import('./routes/Rewind'));
const FastForward = lazy(() => import('./routes/FastForward'));
const News = lazy(() => import('./routes/News'));
const Rewriter = lazy(() => import('./routes/Rewriter'));

const Discard = lazy(() => import('./routes/rewriter-routes/Discard'));
const SaveTheTape = lazy(() => import('./routes/rewriter-routes/SaveTheTape'));
//const VoteList = lazy(() => import('./routes/rewriter-routes/VoteList'));
const Withdraw = lazy(() => import('./routes/rewriter-routes/Withdraw'));
const Swap = lazy(() => import('./routes/rewriter-routes/Swap'));
const Results = lazy(() => import('./routes/rewriter-routes/Results'));

function withSuspense(element: React.ReactNode) {
	return (
		<Suspense fallback={<div className={styles.loader}></div>}>
			{element}
		</Suspense>
	);
}

export function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<AppBody />}>
					<Route index element={withSuspense(<Home/>)}/>
					<Route path="my-tapes" element={withSuspense(<MyTapes/>)}/>
					<Route path="wallet" element={withSuspense(<Wallet/>)}/>
					<Route path="browse" element={withSuspense(<Browse/>)}/>
					<Route path="tape" element={<Outlet/>}>
						<Route index element={
							<main style={{ padding: "1rem" }}>
								<p>Please specify a tape number</p>
							</main>
						}/>
						<Route path=":tapeId" element={withSuspense(<Tape/>)}/>
					</Route>
					<Route path="user" element={<Outlet/>}>
						<Route index element={
							<main style={{ padding: "1rem" }}>
								<p>Please specify a wallet address</p>
							</main>
						}/>
						<Route path=":walletAddress" element={withSuspense(<User/>)}/>
					</Route>
					<Route path="*"
						element={
							<main style={{ padding: "1rem" }}>
								<p>404 Not Found</p>
							</main>
						}
					/>
					<Route path="vote" element={withSuspense(<VoteList/>)}/>

					<Route path="wiki" element={withSuspense(<Wiki/>)}/>
					<Route path="team" element={withSuspense(<TheTeam/>)}/>
					<Route path="rarity" element={withSuspense(<RarityChart/>)}/>
					<Route path="rewind" element={withSuspense(<Rewind/>)}/>
					<Route path="fast-forward" element={withSuspense(<FastForward/>)}/>
					<Route path="news" element={withSuspense(<News/>)}/>

					<Route path="rewriter" element={<Outlet/>}>
						<Route index element={withSuspense(<Rewriter/>)}/>
						<Route path="discard" element={withSuspense(<Discard/>)}/>
						<Route path="save-the-tape" element={withSuspense(<SaveTheTape/>)}/>
						<Route path="vote" element={withSuspense(<VoteList/>)}/>
						<Route path="withdraw" element={withSuspense(<Withdraw/>)}/>
						<Route path="swap" element={withSuspense(<Swap/>)}/>
						<Route path="results" element={withSuspense(<Results/>)}/>
					</Route>
				</Route>
			</Routes>
		</Router>
	);
}