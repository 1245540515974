import { Outlet } from "react-router-dom";
import { WalletProviderWrapper } from './WalletProviderWrapper';
import { Navigation } from './Navigation';
import { WithChildren } from './utils.js';

import styles from "./App.module.css";

export function AppBody({ children }: WithChildren<{}>) {
	return (
		<WalletProviderWrapper>
			<Navigation />
			<div className={styles.content}>
				{/*
				Outlet represents the content of the page, whatever it is
				(the router will take care of placing it here)
				*/}
				<Outlet />
			</div>
		</WalletProviderWrapper>
	);
}